import React from "react";
import "./Navbar.scss";
import { Web3Button } from "../ui/ConnectButton/Web3Button";

const Navbar = () => {
  const handleClick = (e:any, id:any) => {
    e.preventDefault();
    const targetElement = document.getElementById(id);
    window.scrollTo({
      top: targetElement?.offsetTop,
      behavior: "smooth",
    });
  };

  return (

    <nav className="navbar">
      <div className="navbar-left">
        <img
          src="/images/fake-logo.png" // Replace with the path to your logo image
          alt="Logo"
          className="navbar-logo"
        />
        <h3>Collection</h3>
      </div>
      <div className="navbar-right">
        <a href="#about" onClick={(e) => handleClick(e, "about")}>
          <img
            src="/images/fake-logo.png"
            alt="small-logo"
            className="logo-small"
          />
          About
        </a>
        <a href="#roadmap" onClick={(e) => handleClick(e, "roadmap")}>
          <img
            src="/images/fake-logo.png"
            alt="small-logo"
            className="logo-small"
          />
          Roadmap
        </a>
        <a href="#team" onClick={(e) => handleClick(e, "team-members")}>
          <img
            src="/images/fake-logo.png"
            alt="small-logo"
            className="logo-small"
          />
          Team
        </a>
        <a href="#investors" onClick={(e) => handleClick(e, "investors")}>
          <img
            src="/images/fake-logo.png"
            alt="small-logo"
            className="logo-small"
          />
          Investors
        </a>

        <a href="#faq" onClick={(e) => handleClick(e, "faq")}>
          <img
            src="/images/fake-logo.png"
            alt="small-logo"
            className="logo-small"
          />
          Faq
        </a>
      </div>
      <div className="navbar-connect">
        <div className="social-links">
          <a target="_blank" rel="noreferrer" href="https://twitter.com/fakecollecti0n"  className="social-link">
            <img src="/images/icons/twitter-fff.png" alt=""  className="social-icon" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/fcollecti0n" className="social-link">
            <img src="/images/icons/opensea-fff.png" alt="" className="social-icon" />
          </a>
        </div>

        <div className="connect-button"> 
          <Web3Button />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
