import React from "react";
import "./Roadmap.scss";

// const roadmapData = [
//   {
//     title: "Fake token",
//     description:
//       "$FAKE token is the perfect investment opportunity for those who love to lose money. Join the ranks of our fake millionaires, and watch as your net worth plummets to new lows!",
//   },
//   {
//     title: "Metaverse",
//     description:
//       "Fake Metaverse is the ultimate escape from reality, where you can create your own fake identity and do fake things with fake people.",
//   },
//   {
//     title: "P2E game",
//     description:
//       "Fake P2E game is the epitome of fake competition, where you can battle it out with other players for the chance to win fake prizes.",
//   },
//   {
//     title: "The Fake Studio",
//     description:
//       "Fake studio is where dreams go to die, as we churn out the worst movies in history for all to see.",
//   },
//   {
//     title: "Fake Merch",
//     description:
//       "Whether you're looking for a simulated designer bag or a fake gold chain, we've got it all - or at least, something that looks like it.",
//   },
//   {
//     title: "Fake Fest",
//     description:
//       "Fake Fest is the ultimate party destination with fake music, fake food, and fake people, you'll forget it, you won’t actually have fun!",
//   },
// ];

// const RoadmapItem = ({ title, description, index }) => (
//   <div className={`roadmap-item ${index % 2 === 0 ? "left" : "right"}`}>
//     <div className="circle" />
//     <div className="content">
//       <h3>{title}</h3>
//       <p>{description}</p>
//     </div>
//   </div>
// );

const Roadmap = () => {
  return (
    <div className="roadmap" id="roadmap">
      <h2 className="header-with-logo">
        <img
          src="/images/fake-logo.png"
          alt="fake-logo"
          className="navbar-logo"
        />{" "}
        Map
      </h2>
      <div className="roadmap-container">
        {/* {roadmapData.map((item, index) => (
          <RoadmapItem
            key={index}
            title={item.title}
            description={item.description}
            index={index}
          />
        ))} */}
        <img src="/images/fakemap.webp" alt="fakemap" />
      </div>
    </div>
  );
};

export default Roadmap;
