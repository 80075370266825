import React from 'react';
import { useWeb3Context } from '../../../context/Web3Context';

interface ConnectProps {
  connect: (() => Promise<void>) | null
}

const ConnectButton = ({ connect }: ConnectProps) => {
  return connect ? (
    <button onClick={connect} style={{display: "flex", alignItems:"center", gap: "0 5px"}} className="connect-btn">Connect Wallet <img src="/images/icons/arrow-right1.png" style={{height: "15px",}} alt="" /></button>
  ) : (
    <button disabled className="connect-btn">Loading...</button>
  )
}

interface DisconnectProps {
  disconnect: (() => Promise<void>) | null
}

const DisconnectButton = ({ disconnect }: DisconnectProps) => {
  const { address } = useWeb3Context();

  return disconnect ? (
    <>
      <button onClick={disconnect} className="connect-btn">Disconnect</button>
      <div className="info">
        <div>
          <p style={{margin: '0 0 0 12px', color: 'white'}}>{address.substr(0, 4) + '...' + address.substr(address.length - 4, address.length)}</p>
          {/* <p>0.21 Weth</p> */}
        </div>
      </div>
    </>
  ) : (
    <button disabled className="connect-btn">Loading...</button>
  )
}

export function Web3Button() {
  const { web3Provider, connect, disconnect } = useWeb3Context()

  return web3Provider ? (
    <DisconnectButton disconnect={disconnect} />
  ) : (
    <ConnectButton connect={connect} />
  )
}