import React from "react";
import "./TeamMembers.scss";

const teamMembers = [
  {
    id: 1,
    role: "Co-Founder & CEO",
    imgName: "founder-ceo",
    description: [
      "10 years of experience in leading successful companies",
      "Entrepreneur of the Year Award winner",
      "Featured in Forbes' 30 under 30 list",
      "Nobel Prize laureate",
    ],
  },
  {
    id: 2,
    role: "Co-Founder & CTO",
    imgName: "founder-cto",
    description: [
      "10 years of experience in groundbreaking technologies",
      "Edison Award and World Technology Award winner",
      "Turing Award recipient",
      "Emmy Award winner",
    ],
  },
  {
    id: 3,
    role: "Art Director",
    imgName: "founder-art-director",
    description: [
      "10 years of experience in creative design",
      "Cannes Lions and Red Dot Award winner",
      "MacArthur Fellowship recipient",
      "Academy Award (Oscar) winner",
    ],
  },
  {
    id: 4,
    role: "Artist",
    imgName: "artist",
    description: [
      "10 years of experience in fine arts",
      "Turner Prize and Venice Biennale Golden Lion winner",
      "National Medal of Arts recipient",
      "Grammy Award winner",
    ],
  },
  {
    id: 5,
    role: "Solidity Dev",
    imgName: "solidity-dev",
    description: [
      "10 years of experience in blockchain development",
      "Satoshi Award recipient",
      "Expert in smart contract technology",
      "Pulitzer Prize winner",
    ],
  },
  {
    id: 6,
    role: "Frontend Dev",
    imgName: "frontend-dev",
    description: [
      "10 years of experience in web development",
      "Webby Award and Awwwards Site of the Year winner",
      "National Design Award recipient",
      "Tony Award winner",
    ],
  },
  {
    id: 7,
    role: "Backend Dev",
    imgName: "backend-dev",
    description: [
      "10 years of experience in back-end systems",
      "Innovator in serverless technology",
      "Stack Overflow User of the Year Award winner",
      "BAFTA Award winner",
    ],
  },
  {
    id: 8,
    role: "Community Manager",
    imgName: "community-manager",
    description: [
      "10 years of experience in community management",
      "Expert in creating engaging online communities",
      "CMX Community Builder Award winner",
      "Golden Globe Award winner",
    ],
  },
];

// ...
const TeamMembers = () => {
  return (
    <div className="team-members" id="team-members">
      <h2 className="header-with-logo">
        <img
          src="/images/fake-logo.png"
          alt="fake-logo"
          className="navbar-logo"
        />{" "}
        Team
      </h2>
      <div className="team-grid">
        {teamMembers.map((member) => (
          <div key={member.id} className="team-member">
            <div className="team-member-image">
              <img src={`/images/team/${member.id}.png`} alt={member.role} />
              <div className="team-member-overlay">
                <ul className="description">
                  {member.description.map((desc, index) => (
                    <li key={index}>{desc}</li>
                  ))}
                </ul>
              </div>
              <h3 className="team-member-role"> {member.role}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
// ...

export default TeamMembers;
