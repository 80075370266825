import { useEffect, useState } from "react";

import 'react-toastify/dist/ReactToastify.css';

import "./App.scss";
import Navbar from "./components/Navbar/Navbar";
import NewsTicker from "./components/Ticker/NewsTicker";
import MainSection from "./components/MainSection/MainSection";
import About from "./components/About/About";
import TeamMembers from "./components/TeamMembers/TeamMembers";
import Investors from "./components/Investors/Investors";
import Roadmap from "./components/Roadmap/Roadmap";
import FAQ from "./components/FAQ/FAQ";
import "typeface-roboto";
import { useWeb3Context } from "./context/Web3Context";
import { ethers, utils } from "ethers";
import { toast } from "react-toastify";

import { abi, contractAddress } from './configs/abi';
import { trackEvent, trackPageView } from "./GoogleAnalytics";

let contract: any;
let signer: any;

function App() {

  useEffect(() => {
    trackPageView(window.location.pathname);
  }, []);

  const { web3Provider, address } = useWeb3Context();
  const mintPrice = 0.0029;

  const [ state, setState ] = useState({
    totalSupply: 0,
    maxSupply: 6999,
    claimableTokensCount: 999,
    accountBalanceInEth: 0
  });

  const [isLoadingChainData, setIsLoadingChainData] = useState(false);
  const [isLoadingMint, setIsLoadingMint] = useState(false);
  const [mintType, setMintType] = useState('public');
  
  const newsItems = [
    `$BTC got depegged`,
    `Coinbase NFT marketplace volume hit 1bn.`,
    `World's First Crypto Church Opens in Silicon Valley`,
    `Bitcoin Mining Causes Earthquake in China`,
    `Polygon partnered with ALL`,
    `Cardano's TLV reached 69bn.`,
    `Solana network is stable`,
    `Sushiswap released uniswap V4`,
    `Cryptocurrency Donations Now Accepted at the Vatican`,
  ];

  const [count, setCount] = useState(2);
  const minQuantity = 1;
  const maxQuantity = 10;

  function handleMinusClick() {
    if (count > minQuantity) {
      setCount(count - 1);
    }
  }

  function handlePlusClick() {
    if (count < maxQuantity) {
      setCount(count + 1);
    }
  }

  const notifyError = (text: string) => toast.error(text, {hideProgressBar: true});
  
  const getChainData = async  () => {
    setIsLoadingChainData(true);

    Promise.all([
        signer.totalSupply(), 
        web3Provider.getBalance(address),
      ]
    )
    .then(([
      totalSupply, 
      accountBalanceInEth
    ]) => {
      setState({
        ...state, 
        totalSupply: totalSupply.toNumber(),
        accountBalanceInEth: Number(utils.formatEther(accountBalanceInEth)),
      });

      setCount(1);

      if (totalSupply.toNumber() >= state.maxSupply) {
        setMintType('end');
        setIsLoadingChainData(false);
        return;
      }
  
      setIsLoadingChainData(false);
    });
  };

  const mint = async (_count) => {
    trackEvent('click', { event_category: 'purchase', event_label: 'mint', value: _count });

    const quantity = _count;

    if (isLoadingMint || isLoadingChainData) {
      return;
    }

    setIsLoadingMint(true);

    try {
      if (quantity * mintPrice >= state.accountBalanceInEth) {
        notifyError('Insuficent funds!');
        setIsLoadingMint(false);
        return;
      }

      if (quantity === 1) {
        await signer.mint(quantity, {
          value: ethers.utils.parseEther(String(0))
        });

        return;
      }

      if (state.totalSupply >= state.claimableTokensCount) {
        await signer.mint(quantity, {
          value: ethers.utils.parseEther(String((quantity - 1) * mintPrice)),
        });
      } else {
        await signer.mint(quantity, {
          value: ethers.utils.parseEther(String(0))
        });
      }
 
    } catch(err: any) {
      console.log(err);
      notifyError(err.message);
      setIsLoadingMint(false);
    }
  }

  useEffect(() => {
    if (web3Provider && !signer) {
      contract = new ethers.Contract(contractAddress, abi, web3Provider);
      signer = contract.connect(web3Provider.getSigner());

      // signer.on('Mint', (v: string) => {
      //   if (v === address) {
      //     setIsLoadingMint(false);
      //     getChainData();
      //   }
      // });

      getChainData();
    }
  }, [web3Provider, address]);

  const isLeft = <>
    <div className="priceSupply">
      <p>1ST FREE, additional: 0.0029 ETH |</p>
      <p>Supply <span>{state.totalSupply}</span>/{state.maxSupply}</p>
    </div>
  </>

  return (
    <div className="App">
      <Navbar />
      <NewsTicker newsItems={newsItems} />
      <div>
        <MainSection />
        <div className="container">
          <About />
          <Roadmap />
          <TeamMembers />
        </div>
        <Investors />
       <FAQ />
      </div>

      {state.totalSupply !== state.maxSupply ? <>
        <div className={`footerMint ${address ? 'visible' : ''}`}>
         <div className="footer-logo-txt">
            <img src="/images/fake-logo.png" alt="" className="navbar-logo"/>
            <p>Collection</p>
          </div>
        <div className="minting">
          <div className="mint-buttons">
            <span className="minus-btn" onClick={handleMinusClick}>-</span>
            <input
              type="number"
              id="quantity"
              name="quantity"
              value={count}
              min={minQuantity}
              max={maxQuantity}
              onChange={(event) => setCount(event.target.value as any)}
              className="mint-input"
            />
            <span className="plus-btn" onClick={handlePlusClick}>
              +
            </span>
            <div className="mintBtns">
              <button onClick={() => mint(count)} className="mint-button">Mint</button>
              {/* {state.totalSupply} */}
            </div>
          </div>
          <div className="mintBtns">
            <button onClick={() => mint(3)} className="mint-3">Mint 3</button>
            <button onClick={() => mint(5)} className="mint-5">Mint 5</button>
            <button onClick={() => mint(10)} className="mint-10">Mint 10</button>
          </div>
        </div>
        <div className="supply-of">
          {isLeft}
        </div>
      </div>
      </> : <>
        <div className={`footerMint ${address ? 'visible' : ''}`}>
          <div className="supply-of">
            <p>sold <span>Out</span></p>
          </div>
        </div>
      </>}
  </div>
  );
}

export default App;
