import React from "react";
import "./Investors.scss";

const investorsList = [
  { id: 1, name: "Coinbase Ventures", logo: "coinbase-ventures" },
  {
    id: 2,
    name: "a16z",
    logo: "a16z",
  },
  {
    id: 3,
    name: "Binance labs",
    logo: "binance-labs",
  },
  {
    id: 4,
    name: "Delphi Digital",
    logo: "delphi-digital",
  },
  {
    id: 5,
    name: "Multicoin Capital",
    logo: "multicoin-capital",
  },
  {
    id: 7,
    name: "Sino global capital",
    logo: "sino-global-capital",
  },
];

const Investors = () => {
  return (
    <div className="investors" id="investors">
      <h2 className="header-with-logo">
        <img
          src="/images/fake-logo.png"
          alt="fake-logo"
          className="navbar-logo"
        />{" "}
        Investors
      </h2>
      <div className="container">
        <div className="investors-logos">
          {investorsList.map((investor) => (
            <div key={investor.id} className="investor-logo">
              <img src={`/images/${investor.logo}.svg`} alt={investor.name} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Investors;
