import React, { useState } from "react";
import "./FAQ.scss";

const FAQItem = ({ question, answer }:any) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFAQ = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item" onClick={toggleFAQ}>
      <div className="faq-question">
        <h3>{question}</h3>
        <span
          className="faq-arrow"
          style={{ transform: isOpen ? "rotate(180deg)" : "" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
          >
            <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
          </svg>
        </span>
      </div>
      <p className={"faq-answer" + (isOpen ? " open" : "")}>{answer}</p>
    </div>
  );
};

const FAQ = () => {
  const faqData = [
    {
      question: "Can I feed my NFT after midnight?",
      answer: "Yes, feed your NFT responsibly.",
    },
    {
      question:
        "If my NFT gets lost in the blockchain, will it eventually find its way back to me?",
      answer: "It's possible.",
    },
    {
      question: "Can I use my NFT to summon a dragon to ride on?",
      answer: "Absolutely!",
    },
    {
      question: "If I forget my NFT password?",
      answer: "🤦",
    },
    {
      question: "Do you like tacos?",
      answer: "Not really.",
    },
    {
      question: "Can I use my NFT to time travel?",
      answer: "Yes, be careful where you go! Reveal Satoshi, please.",
    },
  ];

  return (
    <div className="faq" id="faq">
      <h2 className="header-with-logo">
        <img
          src="/images/fake-logo.png"
          alt="fake-logo"
          className="navbar-logo"
        />{" "}
        FAQ
      </h2>
      {faqData.map((item, index) => (
        <FAQItem key={index} question={item.question} answer={item.answer} />
      ))}
    </div>
  );
};

export default FAQ;
