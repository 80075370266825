import React, { useRef, useEffect } from "react";
import "./NewsTicker.scss";

const NewsTicker = ({ newsItems }:any) => {
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const container:any = containerRef.current;
    const content:any = contentRef.current;

    const duration = 19000;
    const scrollDistance:any = content["offsetWidth"] / 2;
    const speed = scrollDistance / duration;

    let startTimestamp:any = null;

    const step = (timestamp:number) => {
      if (!startTimestamp) startTimestamp = timestamp;

      const elapsed = timestamp - startTimestamp;
      const progress = elapsed * speed;

      if (progress >= scrollDistance) {
        startTimestamp = timestamp;
      }

      container["scrollLeft"] = progress % scrollDistance;

      requestAnimationFrame(step);
    };

    // Call step once to start the animation immediately
    step(0);
    // Then continue with the requestAnimationFrame loop
    const animationId = requestAnimationFrame(step);

    return () => {
      cancelAnimationFrame(animationId);
    };
  }, [newsItems]);

  return (
    <div className="news-ticker" ref={containerRef}>
      <div className="news-items" ref={contentRef}>
        {newsItems.map((newsItem:any, index:any) => (
          <div key={`first-${index}`} className="news-item">
            <div
              style={{
                display: "inline",
                color: "#0a192f",
                fontStyle: "italic",
              }}
            >
              <i style={{ display: "inline", color: "#e00004" }}> FAKE</i> :
            </div>{" "}
            {newsItem}
          </div>
        ))}
        {/* {newsItems.map((newsItem, index) => (
          <div key={`second-${index}`} className="news-item">
            {newsItem}
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default NewsTicker;
