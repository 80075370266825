import "./About.scss";

const About = () => {
  const nftImages = [1, 2, 3, 4];
  return (
    <div className="about" id="about">
      <div className="about-content">
        <h2 className="header-with-logo">
          About{" "}
          <img
            src="/images/fake-logo.png"
            alt="fake-logo"
            className="navbar-logo"
          />{" "}
          Project
        </h2>
        <p>
          Introducing "FAKE Project" - the revolutionary, decentralized,
          non-fungible token (NFT) platform that takes digital art and
          collectibles to a whole new level! 💥
        </p>
        <p>
          FAKE Project is a groundbreaking, hyper-meta blockchain-powered
          platform, fusing AI-generated art with quantum-proof smart contracts,
          all wrapped up in a delectably innovative, cross-dimensional metaverse
          experience.
        </p>
        <p>
          Our mission is to disrupt the NFT landscape by tokenizing the
          ephemeral essence of creativity, wrapped in a unique, ultra-rare,
          non-fungible package. We're leveraging the synergistic potential of
          next-gen buzzwords, harnessing the power of the distributed ledger to
          tokenize imagination in the cloud.
        </p>
        <p>
          At FAKE Project, we value transparent, decentralized innovation, and
          our team of visionary developers and thought leaders are pioneering
          the future of digital collectibles. We're building the Web 4.0 - one
          exclusive token at a time.
        </p>
      </div>
      <div className="nft-gallery">
        {nftImages.map((_, index) => (
          <div
            className="nft-image-container"
            key={index}
          >
            <img
              src={`./images/about/${index + 1}.png`}
              alt={`NFT ${index + 1}`}
              className="nft-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
