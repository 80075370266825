import "./MainSection.scss";

const MainSection = () => {
  return (
    <div className="main" id="main">
          <img
            src="/images/fakeguy.webp"
            alt="fake-guy"
            className="fake-guy"
          />
      <video className="main-video" autoPlay muted loop>
        <source
          src="/fake guy.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  );
};

export default MainSection;