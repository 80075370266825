const measurementId = 'G-RZ6F0BJRLP'; // Replace with your Google Analytics 4 Measurement ID

// Initialize Google Analytics
const script = document.createElement('script');
script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
script.async = true;
document.head.appendChild(script);

//@ts-ignore
window.dataLayer = window.dataLayer || [];
function gtag() {
    //@ts-ignore
  window.dataLayer.push(arguments);
}
//@ts-ignore
gtag('js', new Date());
//@ts-ignore
gtag('config', measurementId);

export const trackPageView = (page: string, title?: string) => {
  //@ts-ignore
  gtag('event', 'page_view', { page_path: page, page_title: title });
};

export const trackEvent = (
  eventName: string,
  eventParams?: Record<string, any>
) => {
    //@ts-ignore
  gtag('event', eventName, eventParams);
};
